@mixin hover {
  @media screen and (min-width: 1025px) {
    &:hover {
      @content;
    }
  }
}

@mixin anim($offset: 0s,$time: 0.2s, $el: all) {
  transition: $el $time ease-in-out $offset;
}

@mixin media_to($width: 1199px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin media_from($min: 1025px) {
  @media screen and (min-width: $min) {
    @content;
  }
}

@mixin media_from_to($min: 1025px, $max: 1199px) {
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin media_sm {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin media_to_sm {
  @media screen and (min-width: 1025px) {
    @content;
  }
}

@mixin media_only_sm {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin media_xs {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin media_to_xs {
  @media screen and (min-width: 768px) {
    @content;
  }
}
