@import 'variables';
@import 'typography';
@import 'base';
@import 'mixins';
@import './../node_modules/quill/dist/quill.snow.css';

@font-face {
	font-family: 'Kumbh Sans';
	src: url('./fonts/kumbh-sans/KumbhSansVariable.ttf');
	font-weight: 1 999;
}


.change-detected {
	//background: #fffaf0 !important;
}

html {
	font-family: 'Kumbh Sans', sans-serif;
	background-color: var(--color__background);
}

html,
body,
p {
	margin: 0;
	padding: 0;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
	box-sizing: border-box;
	scrollbar-color: var(--medium) var(--light);
	scrollbar-width: auto;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 12px;
}

*::-webkit-scrollbar-track {
	padding: 20px;
	background: transparent;
}

//TODO INSTEAD OF THIS CREATE A CLASS
*::-webkit-scrollbar-thumb {
	transition: background-color 200ms;
	border: 2px solid var(--color__light);
	border-radius: 10px;
	background-color: var(--color__medium);

	&:hover {
		background-color: var(--color__primary);
	}
}

button {
	border: none;
	outline: none;
	background: transparent;
}

a,
button {
	@include anim();

	&:active {
		opacity: 0.75;
	}
}

.c-pointer {
	cursor: pointer;
}

.dark-background {

	/* ===== Scrollbar CSS ===== */
	/* Firefox */
	* {
		scrollbar-color: var(--medium) var(--dark);
		scrollbar-width: auto;
	}

	/* Chrome, Edge, and Safari */
	*::-webkit-scrollbar {
		width: 12px;
	}

	*::-webkit-scrollbar-track {
		padding: 20px;
		background: transparent;
	}

	*::-webkit-scrollbar-thumb {
		transition: background-color 200ms;
		border: 2px solid var(--dark);
		border-radius: 10px;
		background-color: var(--medium);

		&:hover {
			background-color: var(--primary);
		}
	}
}

.epic-tooltip {
	$self: &;
	font-size: var(--font-size__sm);
	position: fixed;
	z-index: 10000;
	padding: 8px 12px;
	transition: 0.3s;
	opacity: 0;
	color: var(--color__light);
	border-radius: 4px;
	background-color: var(--color__dark);
	box-shadow: 4px 4px 6px 0px rgba(66, 68, 90, 0.6);

	&--template {
		color: inherit;
	}

	&--icon-button {
		transition-property: opacity, transform;
		transform: translate(50px);
		pointer-events: none;
		border-radius: 4px 0 0 4px;
		box-shadow: none;

		&#{$self}--right {
			transform: translateX(-15px);
			border-radius: 0 4px 4px 0;
		}
	}

	&--visible {
		transform: translate(7px);
		opacity: 1;
	}

	&--modal {
		font-size: 13px;
		font-weight: 400;
		padding: 6px 7px;
		transform: translateX(calc(-50% + 16px)) translateY(7px);
		color: var(--color__white);
		border-radius: 5px 5px 0px 5px;
		box-shadow: none;
	}

	&--square {
		font-size: 13px;
		font-weight: 400;
		padding: 6px 7px;
		transform: translateX(0) translateY(7px);
		color: var(--color__dark);
		border-radius: 5px;
		background-color: var(--color__primary);
		box-shadow: none;
	}

	&--processed {
		max-width: 400px;
	}

	&--white-space {
		white-space: pre-line;
	}
}

.epic-autocomplete__options {
	position: fixed;
	z-index: 10001;
	display: flex;
	//TODO MODIFY ANGULAR ANIMATION AFTER DEPLOY
	overflow-y: auto !important;
	flex-direction: column;
	max-height: 200px;
	border: 2px solid black;
	border-top: none;
	border-radius: 0 0 5px 5px;
	background: white;

	&.fromBottom {
		border-top: 2px solid black;
		border-bottom: none;
		border-radius: 5px 5px 0 0;
	}
}

.epic-popover__list {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.epic-popover__item {
	font-weight: 500;
	padding: 4px 10px;
	cursor: pointer;

	@include anim();

	@include hover() {
		background-color: var(--color__super-light);
	}

	&:active {
		text-decoration: underline;
	}

	&--danger {
		@include hover() {
			background-color: var(--color__danger-background);
		}
	}
}

.filters {
	&__submit {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		margin-top: 10px;
	}
}

.epic-table__checkbox-cell {
	input {
		pointer-events: none;
	}
}

.required-mark {
	color: var(--color__danger);
}

.no-active {
	user-select: none;
	pointer-events: none;
	opacity: 0.5;
}

.order-list {
	// .epic-form-field {
	// 	epic-autocomplete {
	// 		div {
	// 			.epic-autocomplete {
	// 				border-color: var(--color__success);
	// 			}
	// 		}
	// 	}

	// 	epic-select {
	// 		div {
	// 			.epic-select {
	// 				border-color: var(--color__success);
	// 			}
	// 		}
	// 	}
	// }

	epic-table-pagination .paginator {
		.per-page-label {
			font-size: var(--font-size__sm);
		}

		.pagination-state {
			font-size: var(--font-size__md);
			color: var(--color__medium);
		}

		epic-select {
			div {
				.epic-select {
					font-size: var(--font-size__sm);
				}
			}
		}

		epic-button {
			display: block;

			.epic-button {
				padding: 7px 12px;
				background-color: transparent;

				@include hover {
					outline: none;
				}
			}
		}
	}
}

.epic-table__checkbox-cell {
	input[type='checkbox'] {
		position: relative;
		bottom: 0;
		display: inline-block;
		width: 16px;
		height: 16px;
		margin: 0;
		cursor: pointer;
		user-select: none;
		border-radius: 5px;
		outline: 0;
		appearance: none;

		&:after {
			z-index: 1;
			display: block;
			width: 14px;
			height: 14px;
			content: '';
			transition: background-color 0.3s ease-out;
			border: 1px solid var(--color__medium);
			border-radius: 5px;
			background-color: var(--color__white);
		}

		&:before {
			position: absolute;
			z-index: 2;
			width: 100%;
			height: 100%;
			content: '';
			transition: opacity 0.3s ease-out;
			opacity: 0;
			border-radius: 5px;
			background-image: none;
			background-image: url('../icons/tick.svg');
			background-repeat: no-repeat;
			background-position: center;
			background-size: 10px 7px;
		}

		&:hover:after,
		&:focus:after,
		&:active:after {
			border-color: var(-color__dark);
			background-color: var(--color__light);
		}

		&:checked:before,
		&.active:before {
			opacity: 1;
		}

		&:checked:after,
		&.active:after {
			border-color: var(-color__dark);
		}

		&:checked:after {
			background-color: var(--color__light);
		}
	}
}

.epic-select__options {
	position: fixed;
	//TODO MODIFY ANGULAR ANIMATION AFTER DEPLOY
	z-index: 10001;
	display: flex;
	overflow-y: auto !important;
	flex-direction: column;
	max-height: 200px;
	border: 2px solid var(--color__success);
	border-top: none;
	border-radius: 0 0 5px 5px;
	background: white;

	&.fromBottom {
		border-top: 2px solid var(--color__success);
		border-bottom: none;
		border-radius: 5px 5px 0 0;
	}
}

.form-label {
	font-size: var(--font-size__sm);
	font-weight: 500;
	line-height: 15px;
	overflow: hidden;
	margin-bottom: 2px;
	padding: 0 10px 2px 10px;
	white-space: nowrap;
}

.no-result {
	display: flex;
	min-height: 100px;
	justify-content: center;
	width: 100%;
	height: 100px;

	@extend .font__heading-2, .font-weight__medium;
}

.is-disabled,
input[disabled] {
	@extend .no-active
}

.access-denied {
	display: block;
	width: auto;
	height: 100%;
	opacity: 1;
	background-image: url(../icons/shield-lock.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.submit-row {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding-top: 15px;
}

//TODO REFACTOR
.attachments-costs {
	margin-top: 18px;

	&__text {
		overflow: hidden;
		width: 205px;
		text-overflow: ellipsis;
	}

	&__row {
		align-items: center;
		margin-bottom: 8px;
		margin-left: 5px;

		&-tags {
			display: flex;
			flex-wrap: wrap;
			max-width: 400px;

			span {
				margin-right: 5px;

				&:not(:last-of-type) {
					&:after {
						display: inline;
						content: ',';
					}
				}
			}
		}
	}

	&__unaccepted {
		margin-bottom: 30px;
	}

	&__accepted {
		margin-bottom: 8px;
		border-bottom: 1px solid var(--color__black);
	}

	&__label {
		display: block;
		margin-bottom: 10px;
	}

	epic-icon {
		display: flex;
		justify-content: center;
	}

	epic-select {
		max-width: 220px;
		margin-left: 15px;
	}
}

.invalid,
.ng-invalid.ng-dirty {
	border-color: var(--color__danger) !important;
	background-color: var(--color__invalid-background) !important;
}

.epic-select__value-round {
	margin-right: 6px;
	padding: 4px 6px;
	border: 1px solid var(--color__white);
	border-radius: 5px;
	background-color: var(--color__light);
	@extend .font__small
}

.menu-avatar {
	width: 24px;
	height: 24px;
	transition: 250ms;
}

.previous-email {
	background-color: #3a880c;
}

.tag {
	width: fit-content;
	padding: 2px 6px;
	border-radius: 5px;
	@extend .font__tiny, .font-weight__regular;
}

.report-table {
	width: 100%;
	border-collapse: collapse;
	border-left: 1px solid var(--color__light);

	tr {
		border-bottom: 1px solid var(--color__light);
	}

	td {
		height: 35px;
		border-right: 1px solid var(--color__light);
		padding: 10px;
		vertical-align: top;
	}

	.positive {
		color: var(--color__success);
	}

	.negative {
		color: var(--color__danger);
	}
}

kbd {
	font-family: 'Kumbh Sans', sans-serif;
	font-size: 13px;
	line-height: 1;
	position: relative;
	top: -1px;
	display: block;
	min-width: 20px;
	padding: 2px 5px;
	cursor: default;
	text-align: center;
	letter-spacing: 1px;
	color: #000000;
	border: 1px solid #737373;
	border-radius: 0.25rem;
	background-color: #e1e1e1;
	box-shadow: 0 2px 0 1px #c3c3c3;
}

.focus--border{
	border: 2px solid var(--color__medium) !important;
}