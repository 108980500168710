.row {
  display: flex;
  flex-direction: row;

  &--center {
    align-items: center;
  }

  &__end {
    justify-content: flex-end;
  }
}

.no-wrap {
  flex-wrap: nowrap;
}

.wrap {
  flex-wrap: wrap;
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.column {
  display: flex;
  flex-direction: column;
}

.column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.width {
  &__100 {
    width: 100%;
  }
}

$GAP_VALUE: 20px;

.gap {
  gap: $GAP_VALUE;

  &--minimum {
    gap: 5px;
  }

  &--small {
    gap: $GAP_VALUE * 0.5;
  }

  &--medium {
    gap: $GAP_VALUE * 1.5;
  }

  &--large {
    gap: $GAP_VALUE * 2;
  }
}

.align {
  &__start {
    align-items: flex-start;
  }

  &__center {
    align-items: center;
  }

  &__stretch {
    align-items: stretch;
  }

  &__end {
    align-items: flex-end;
  }
}

.justify {
  &__start {
    justify-content: flex-start;
  }

  &__end {
    justify-content: flex-end;
  }

  &__center {
    justify-content: center;
  }

  &__space-between {
    justify-content: space-between;
  }
}

.border {
  border: 1px solid var(--color__light);

  &__bottom {
    border-bottom: 1px solid var(--color__light);
  }

  &__right {
    border-right: 1px solid var(--color__light);
  }

  &__left {
    border-left: 1px solid var(--color__light);
  }
}

.form, .grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(12, 1fr);

  &__element-1 {
    grid-column: span 1;
  }

  &__element-2 {
    grid-column: span 2;
  }

  &__element-3 {
    grid-column: span 3;
  }

  &__element-4 {
    grid-column: span 4;
  }

  &__element-5 {
    grid-column: span 5;
  }

  &__element-6, &__half {
    grid-column: span 6;
  }

  &__element-7 {
    grid-column: span 7;
  }

  &__element-8 {
    grid-column: span 8;
  }

  &__element-9 {
    grid-column: span 9;
  }

  &__element-10 {
    grid-column: span 10;
  }

  &__element-11 {
    grid-column: span 11;
  }

  &__element-12, &__full {
    grid-column: span 12;
  }

  &.no-gap {
    grid-gap: 0;
  }
}

$PADDING_VALUE: 10px;

.padding {
  padding: $PADDING_VALUE;

  &--small {
    padding: $PADDING_VALUE * 0.5;
  }

  &--medium {
    padding: $PADDING_VALUE * 1.5;
  }

  &--large {
    padding: $PADDING_VALUE * 2;
  }

  &__top {
    padding-top: $PADDING_VALUE;

    &--small {
      padding-top: $PADDING_VALUE * 0.5;
    }

    &--medium {
      padding-top: $PADDING_VALUE * 1.5;
    }

    &--large {
      padding-top: $PADDING_VALUE * 2;
    }
  }

  &__bottom {
    padding-bottom: $PADDING_VALUE;

    &--small {
      padding-bottom: $PADDING_VALUE * 0.5;
    }

    &--medium {
      padding-bottom: $PADDING_VALUE * 1.5;
    }

    &--large {
      padding-bottom: $PADDING_VALUE * 2;
    }
  }

  &__left {
    padding-left: $PADDING_VALUE;

    &--small {
      padding-left: $PADDING_VALUE * 0.5;
    }

    &--medium {
      padding-left: $PADDING_VALUE * 1.5;
    }

    &--large {
      padding-left: $PADDING_VALUE * 2;
    }
  }

  &__right {
    padding-right: $PADDING_VALUE;

    &--small {
      padding-right: $PADDING_VALUE * 0.5;
    }

    &--medium {
      padding-right: $PADDING_VALUE * 1.5;
    }

    &--large {
      padding-right: $PADDING_VALUE * 2;
    }
  }

  &__horizontal {
    padding-left: $PADDING_VALUE;
    padding-right: $PADDING_VALUE;

    &--small {
      padding-left: $PADDING_VALUE * 0.5;
      padding-right: $PADDING_VALUE * 0.5;
    }

    &--medium {
      padding-left: $PADDING_VALUE * 1.5;
      padding-right: $PADDING_VALUE * 1.5;
    }

    &--large {
      padding-left: $PADDING_VALUE * 2;
      padding-right: $PADDING_VALUE * 2;
    }
  }

  &__vertical {
    padding-bottom: $PADDING_VALUE;
    padding-top: $PADDING_VALUE;

    &--small {
      padding-bottom: $PADDING_VALUE * 0.5;
      padding-top: $PADDING_VALUE * 0.5;
    }

    &--medium {
      padding-bottom: $PADDING_VALUE* 1.5;
      padding-top: $PADDING_VALUE * 1.5;
    }

    &--large {
      padding-bottom: $PADDING_VALUE* 2;
      padding-top: $PADDING_VALUE * 2;
    }
  }
}

$MARGIN_VALUE: 10px;

.margin {
  margin: $MARGIN_VALUE;

  &--small {
    margin: $MARGIN_VALUE * 0.5;
  }

  &--medium {
    margin: $MARGIN_VALUE * 1.5;
  }

  &--large {
    margin: $MARGIN_VALUE * 2;
  }

  &__top {
    margin-top: $MARGIN_VALUE;

    &--small {
      margin-top: $MARGIN_VALUE * 0.5;
    }

    &--medium {
      margin-top: $MARGIN_VALUE * 1.5;
    }

    &--large {
      margin-top: $MARGIN_VALUE * 2;
    }
  }

  &__bottom {
    margin-bottom: $MARGIN_VALUE;

    &--small {
      margin-bottom: $MARGIN_VALUE * 0.5;
    }

    &--medium {
      margin-bottom: $MARGIN_VALUE * 1.5;
    }

    &--large {
      margin-bottom: $MARGIN_VALUE * 2;
    }
  }

  &__left {
    margin-left: $MARGIN_VALUE;

    &--small {
      margin-left: $MARGIN_VALUE * 0.5;
    }

    &--medium {
      margin-left: $MARGIN_VALUE * 1.5;
    }

    &--large {
      margin-left: $MARGIN_VALUE * 2;
    }
  }

  &__right {
    margin-right: $MARGIN_VALUE;

    &--small {
      margin-right: $MARGIN_VALUE * 0.5;
    }

    &--medium {
      margin-right: $MARGIN_VALUE * 1.5;
    }

    &--large {
      margin-right: $MARGIN_VALUE * 2;
    }
  }

  &__horizontal {
    margin-left: $MARGIN_VALUE;
    margin-right: $MARGIN_VALUE;

    &--small {
      margin-left: $MARGIN_VALUE * 0.5;
      margin-right: $MARGIN_VALUE * 0.5;
    }

    &--medium {
      margin-left: $MARGIN_VALUE * 1.5;
      margin-right: $MARGIN_VALUE * 1.5;
    }

    &--large {
      margin-left: $MARGIN_VALUE * 2;
      margin-right: $MARGIN_VALUE * 2;
    }
  }

  &__vertical {
    margin-bottom: $MARGIN_VALUE;
    margin-top: $MARGIN_VALUE;

    &--small {
      margin-bottom: $MARGIN_VALUE * 0.5;
      margin-top: $MARGIN_VALUE * 0.5;
    }

    &--medium {
      margin-bottom: $MARGIN_VALUE* 1.5;
      margin-top: $MARGIN_VALUE * 1.5;
    }

    &--large {
      margin-bottom: $MARGIN_VALUE* 2;
      margin-top: $MARGIN_VALUE * 2;
    }
  }
}

.page-container {
  margin: 0 auto;
  max-width: 100%;
  padding: 0 30px 30px 30px;
}

.block-container {
  padding: 20px;
}

.link {
  color: var(--color__info);
  cursor: pointer;
  font-weight: 500;
  transition: 250ms;
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }

  &.link--disabled {
    color: var(--color__medium);
    cursor: not-allowed;
  }
}

.column {
  &--100 {
    max-width: 100px;
    min-width: 100px;
    width: 100px;
  }
}

.card {
  $self: &;

  border: 2px solid var(--color__super-light);
  padding: 10px;
  transition: 500ms all;

  &--success {
    background-color: var(--color__success-background);
    border-color: var(--color__success);

  }

  &--danger {
    background-color: var(--color__danger-background);
    border-color: var(--color__danger);
  }

  &--warning {
    background-color: var(--color__warning-background);
    border-color: var(--color__warning);
  }

  &--rainbow {
    --angle: 45deg;
    --opacity: 0.5;
    background: var(--color__primary-background);

    border: var(--border-size) solid transparent;
    border-image: conic-gradient(from var(--angle),
            var(--color__danger) 0deg 90deg,
            var(--color__primary) 90deg 180deg,
            var(--color__info) 180deg 270deg,
            var(--color__success) 270deg 360deg) 1 stretch;

    --border-size: 2px;

    transition: 500ms all;
  }

  epic-loader-content {
    transform: scale(0.75);
  }
}

@supports (background: paint(houdini)) {
  @property --opacity {
    syntax: '<number>';
    initial-value: 0.5;
    inherits: false;
  }
  @property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
  }

  @keyframes opacityChange {
    to {
      --opacity: 1;
    }
  }

  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }

  .card--rainbow {
    animation: rotate 4s linear infinite, opacityChange 3s infinite alternate;
  }
}

.background {
  &__danger {
    background-color: var(--color__danger-background) !important;
  }

  &__super-light {
    background-color: var(--color__super-light);
  }
}

.clickable {
  cursor: pointer;
  //font-weight: 400;
  transition-duration: 200ms, 100ms;
  transition-property: text-decoration, opacity;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    opacity: 0.60;
  }

  &__extinguished {
    opacity: 0.35;
    transition: 200ms;

    &:hover {
      opacity: 1;
    }
  }
}

.file-picker {
  &__standard {
    @extend .font__small;

    align-items: center;
    border: 1px dashed var(--color__medium);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    padding: 15px;
    width: 100%;
  }
}

.table-column {
  &__xs {
    width: 48px;
  }

  &__sm {
    width: 120px;
  }

  &__md {
    width: 180px;

    &--max {
      max-width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__lg {
    width: 240px;

    &--max {
      max-width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.epic-table__checkbox-cell {
  text-align: center;
  width: 48px;
}

.vertical-align {
  &__top {
    vertical-align: top;
  }
}

.alternative-button {
  cursor: pointer;
  padding: 4px 12px;
  background-color: transparent;
  border: 1px solid var(--color__medium);
  border-radius: 30px;
  font-size: 0.75em;
  font-weight: 600;
  transition: 250ms;

  &:hover {
    background-color: rgba(#000, 0.05);
    border-color: #000;
  }
}
.text-center {
	text-align: center;
}
.cdk-drag-preview {
	background: #ccc;
	border: 1px solid #000;
	padding: 5px;
	border-radius: 4px;
}

.cdk-drop-list-dragging {
	border: 2px dashed #aaa;
}
.cost-invoice-loader {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.flex-container{
  display: flex;
}