:root {
	// Colors
	--color__primary: #FFD203;
	--color__success: #34D293;
	--color__warning: #FFD203;
	--color__info: #006fff;
	--color__danger: #E31E25;

	--color__super-light: #EBEBEB;
	--color__light: #E6E6E6;
	--color__medium: #A1A1A1;
	--color__white: #FFFFFF;

	--color__dark: #212121;
	--color__black: #000000;

	--color__light-yellow: #FFF1B2;
	--color__cyan: #3A880C;

	--color__background: #EBEBEB;
	--color__contrast-background: #F3F3F3;

	--color__primary-background: #F3F3F3;
	--color__success-background: #DEFEF1;
	--color__warning-background: #FFF1B2;
	--color__info-background: #a8cfff;
	--color__danger-background: #FFDCDE;

	--color__invalid-background: #fff4f4;

	--color-special: #b595cd;

	--color__mail-blue: #778DAB;

	// Font size
	--font-size: 14px;
	--font-size__xs: 11px;
	--font-size__sm: 12px;
	--font-size__md: 14px;
	--font-size__lg: 16px;
	--font-size__xxl: 35px;
	--font-size__h1: 40px;
	--font-size__h2: 20px;

	// Padding
	--padding__xs: 5px;
	--padding__sm: 10px;
	--padding__md: 15px;
	--padding__lg: 20px;

	--shadow: rgba(0, 0, 0, 0.075) 0px 20px 30px;
	--color__light-transparent: rgba(230, 230, 230, 0.53);

	--icon-colour: #212121;
	--status-colour: #E6E6E6;
}