.font {

  /* Font size 10px | Line height 13px */
  &__tiny {
    font-size: 10px;
    line-height: 13px;
  }

  /* Font size 12px | Line height 15px */
  &__small {
    font-size: 12px;
    line-height: 15px;
  }

  /* Font size 14px | Line height 18px */
  &__medium {
    font-size: 14px;
    line-height: 18px;
  }

  /* Font size 16px | Line height 20px */
  &__large {
    font-size: 16px;
    line-height: 20px;
  }

  // Heading

  /* Font size 40px | Line height 49px */
  &__heading-1 {
    font-size: 40px;
    line-height: 49px;
  }

  /* Font size 20px | Line height 25px */
  &__heading-2 {
    font-size: 20px;
    line-height: 25px;
  }

  /* Font size 17px | Line height 21px */
  &__heading-3 {
    font-size: 17px;
    line-height: 21px;
  }
}

.font-weight {

  /* Thin 100 */
  &__thin {
    font-weight: 100;
  }

  /* Light 300 */
  &__light {
    font-weight: 300;
  }

  /* Regular 400 */
  &__regular {
    font-weight: 400;
  }

  /* Medium 500 */
  &__medium {
    font-weight: 500;
  }

  /* Semi bold 600 */
  &__semibold {
    font-weight: 600;
  }

  /* Bold 700 */
  &__bold {
    font-weight: 700;
  }

  /* Black 900 */
  &__black {
    font-weight: 900;
  }
}

.font-style {
  /* Normal */
  &__normal {
    font-style: normal;
  }

  /* Italic */
  &__italic {
    font-style: italic;
  }
}

.no-wrap-text {
  text-wrap: nowrap;
}

.text-color {
  &__muted {
    color: var(--color__medium);
  }

  &__success {
    color: var(--color__success);
  }

  &__danger {
    color: var(--color__danger);
  }

  &__info {
    color: var(--color__info);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  @extend .font__heading-1;
}

h2 {
  @extend .font__heading-2;
}

h3 {
  @extend .font__heading-3;
}

.header-font_size-xxl {
  font-size: var(--font-size__xxl);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

label {
  @extend .font__small;
  @extend .font-weight__medium;
  cursor: pointer;
}

.font-align {
  &__left {
    text-align: left;

    .cell {
      span {
        text-align: left;
        width: 100%;
      }
    }
  }

  &__center {
    text-align: center;

    .cell {
      span {
        text-align: center;
        width: 100%;
      }
    }
  }

  &__right {
    text-align: right;

    .cell {
      span {
        text-align: right;
        width: 100%;
      }
    }
  }
}

.font-vertical-align {
  &__top {
    vertical-align: top;
  }

  &__middle {
    vertical-align: middle;
  }

  &__bottom {
    vertical-align: bottom;
  }
}

.column-width {

  &__full {
    display: block;
    max-width: 100%;
    min-width: 100%;
    width: 100%;

  }

  &__200 {
    display: block;
    max-width: 200px;
    min-width: 200px;
    width: 200px;

  }

  &__min-100 {
    display: block;
    min-width: 100px;
  }

  &__150 {
    display: block;
    max-width: 150px;
    min-width: 150px;
    width: 150px;
  }

  &__50 {
    display: block;
    max-width: 50px;
    min-width: 50px;
    width: 50px;
  }

  &__26 {
    display: block;
    max-width: 26px;
    min-width: 26px;
    width: 26px;
  }
}